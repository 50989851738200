@import url("https://fonts.googleapis.com/css2?family=Mona+Sans:wght@400&family=Unbounded:wght@400&display=swap");

html,
body {
	overflow-x: hidden;
	width: 100%;
	font-family: "Mona Sans";
}

* {
	box-sizing: border-box;
}

.circle-number {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #ff9e43;
	color: #000158;
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	filter: invert(0) sepia(1) saturate(1000%) hue-rotate(200deg);
}

.carousel-indicators [data-bs-target] {
	background-color: #ff9e43;
}

.carousel-indicators [data-bs-target].active {
	background-color: #ff9e43;
}
.faq-number {
	width: 50px;
	height: 50px;
	background-color: #000158;
	color: aliceblue;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	left: 20px;
	top: -30px;
}

.card-number {
	width: 50px;
	height: 50px;
	background-color: #000158;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	left: 20px;
	top: -30px;
}

.card-number2 {
	width: 30px;
	height: 30px;
	background-color: #ff9e43;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: #000;
	position: absolute;
}
